/* Menu.css */

/* General Section Styling */
.menu-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.category-buttons {
  display: flex;
  flex-wrap: wrap; /* Allow buttons to wrap onto new lines */
  gap: 10px;
  justify-content: center; /* Center buttons */
}

.category-button {
  padding: 8px 15px;
  background-color: #4CAF50; /* Green background color */
  color: white;
  border-radius: 20px;
  cursor: pointer;
  font-weight: bold;
  transition: background-color 0.3s ease;
  text-transform: uppercase;
  font-size: 14px;
  min-width: 100px;
  text-align: center;
  box-sizing: border-box;
  border: none;
}

.category-button:hover {
  background-color: #45a049; /* Darker green on hover */
}

.category-section {
  margin-bottom: 40px;
  padding: 20px;
  /* background-color: rgba(0, 0, 0, 0.5); Transparent black background */
  /* border-radius: 10px; */
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
  /* color: antiquewhite; */
}

/* Menu Items Styling */
.category-section ul {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Two columns */
  gap: 20px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.category-section li {
  background-color: rgba(3, 3, 3, 0.274); /* Light transparent background */
  padding: 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: antiquewhite;
}

.category-section li h3 {
  margin: 0;
  font-size: 1.2rem;
  color: #bfc571;
}

.category-section li p {
  margin: 5px 0;
  font-size: 1rem;
  color: #d5dce7;
}

.category-section li p.price {
  font-weight: bold;
  color: #87a191;
}

.category-section li:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}

/* Title and Button Container Styling */
.title-and-button {
  display: flex; /* Use Flexbox for horizontal alignment */
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Add space between the title and button */
  margin-bottom: 20px; /* Add spacing below the row */
}

/* Adjust the Title Style */
.title-and-button h2 {
  margin: 0; /* Remove extra margin from the title */
  font-size: 1.8rem; /* Adjust font size if needed */
  color: #f1998f; /* Highlight color */
}

/* Title and Button Container Styling */
.title-and-button {
  display: flex; /* Use Flexbox for horizontal alignment */
  align-items: center; /* Vertically center the items */
  justify-content: space-between; /* Add space between the title and button */
  margin-bottom: 20px; /* Add spacing below the row */
}

/* Adjust the Title Style */
.title-and-button h2 {
  margin: 0; /* Remove extra margin from the title */
  font-size: 1.8rem; /* Adjust font size if needed */
  color: #ffa79e; /* Highlight color */
}

/* Adjust the Button Style */
.back-to-top-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px; /* Keep the button small */
  height: 50px;
  background-color: #9e726d;
  color: white;
  border: none;
  border-radius: 50%; /* Circular button */
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  font-size: 1.5rem; /* Icon size */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a shadow for better visibility */
}

.back-to-top-button:hover {
  background-color: #ffa79e;
  transform: translateY(-5px); /* Lift effect */
}

.warning-text {
  color: #ce7823; /* Darker brown for text */
  font-weight: bold;
  background-color:#151515a0 ;
  font-size: 1rem;
  padding: 10px 15px;
  border-left: 5px solid  #ce7823 ; /* Orange warning accent */
  border-radius: 5px;
  margin-top: 10px;
  display: inline-block;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}


/* Responsive Design */
@media (max-width: 992px) {
  .category-section ul {
    grid-template-columns: 1fr; /* Single column on smaller screens */
  }
}
