.post-detail {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: antiquewhite;
  }
  
  .post-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .post-title {
    font-size: 2.5rem;
    margin-top: 20px;
    color: #ffa79e;
    text-align: center;
  }
  
  .post-content {
    font-size: 1.2rem;
    color: rgb(217, 199, 175);
    line-height: 1.8;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 16px;
    line-height: 1.6;
  }

  .post-content p {
    margin-bottom: 1.5rem;
  }
  
  .post-content p + p {
    margin-top: 1rem;
  }

  .go-back-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .go-back-button:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 992px) {
    .post-detail {
      padding: 15px;
    }
  
    .post-title {
      font-size: 2rem;
    }
  
    .post-content {
      font-size: 1.1rem;
    }
  }
  
  @media (max-width: 768px) {
    .post-detail {
      padding: 10px;
    }
  
    .post-title {
      font-size: 1.8rem;
    }
  
    .post-content {
      font-size: 1rem;
    }
  }  