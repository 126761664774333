/* Blog Section Styling */
.blog-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blog-section h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 30px;
    color: #e8695a; 
  }
  .blog-posts {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0;
  }

  .blog-post {
    color: antiquewhite;
    font-size: large;
    font-weight: 300;
    background-color: rgba(0, 0, 0, 0.3);
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: calc(33.333% - 20px);
    margin: 10px 0;
    padding: 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .blog-post:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .blog-post img {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 8px;
  }
  
  .blog-post h3 {
    font-size: 1.5rem;
    margin-top: 15px;
    color: #ffa79e;
  }
  
  .blog-post p {
    font-size: 1rem;
    color: rgb(217, 199, 175);
    margin: 10px 0;
  }
  
  .blog-post a.read-more {
    display: inline-block;
    margin-top: 15px;
    padding: 10px 20px;
    background-color: #e74c3c;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .blog-post a.read-more:hover {
    background-color: #c0392b;
  }
  
 
  @media (max-width: 992px) {
    .blog-post {
      width: calc(50% - 20px); 
    }
  }
  
  @media (max-width: 768px) {
    .blog-post {
      width: 100%;
    }
  }