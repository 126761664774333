
.day-buttons {
    display: flex;
    flex-wrap: wrap; /* Allow buttons to wrap onto new lines */
    gap: 10px;
    justify-content: center; /* Center buttons */
  }
  
  .day-button {
    padding: 8px 15px;
    background-color: #4CAF50; /* Green background color */
    color: white;
    border-radius: 20px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
    text-transform: uppercase;
    font-size: 14px;
    min-width: 100px;
    text-align: center;
    box-sizing: border-box;
    border: none;
  }
  
  .day-button:active {
    background-color: #45a049; /* Darker green on hover */
  }

.dagens-meny {
    padding: 20px;
    margin: 20px auto;
    text-align: center;
  }
  
  .dagens-meny h2 {
    font-size: 1.8rem;
    margin-bottom: 20px;
    color: #ffa79e; /* Samma färg som övriga titlar */
  }
  
  .dagens-meny .price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #87a191; /* Färgen anpassad till tidigare design */
    margin-bottom: 15px;
  }
  
  .dagens-meny .menu-items {
    list-style: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Två kolumner */
    gap: 20px;
  }
  .menu-item {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    gap: 5px; /* Adds some spacing between elements */
  }
  
  .menu-item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .menu-item h4 {
    margin: 0; /* Remove default margin */
  }
  
  .menu-item p {
    margin-top: 5px; /* Ensure description appears on a new line */
    text-align: left; /* Align text to the left for better readability */
  }
  
  .dagens-meny .menu-item {
    background-color: rgba(3, 3, 3, 0.274); /* Transparent bakgrund */
    padding: 15px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .dagens-meny .menu-item h3 {
    font-size: 1.2rem;
    color: #bfc571; /* Samma färg som övriga rubriker */
    margin: 0;
  }
  
  .dagens-meny .menu-item p {
    font-size: 1rem;
    color: #d5dce7;
    margin: 5px 0;
  }
  
  .dagens-meny .menu-item:last-child {
    border-bottom: none;
  }
  
  .dagens-meny .menu-item:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  }
  
  /* Responsiv design */
  @media (max-width: 992px) {
    .dagens-meny .menu-items {
      grid-template-columns: 1fr; /* Enkel kolumn för mindre skärmar */
    }
  }
  