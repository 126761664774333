body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #fff;
    background-color: #1a1a1a; /* Mörk bakgrund för kontrast */
}

h2 {
    font-size: 1.4rem; /* Större rubrik */
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border-bottom: 2px solid #ff9900;
    display: inline-block;
    padding-bottom: 5px;
}

h3 {
    font-size: 1rem;
    margin-bottom: 10px;
    color: #ffcc00;
}

p {
    font-size: 1.2rem; /* Större brödtext */
    line-height: 1.8;
    margin: 0 0 10px;
}

a {
    color: #ffcc00;
    text-decoration: none;
    font-weight: bold;
}

.container {
    max-width: 1400px; /* Ökad bredd för modern look */
}

#location .location-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 30px; /* Ökad avstånd mellan sektioner */
}

#location .location-section {
    flex: 1;
    min-width: 300px;
}

iframe {
    width: 100%;
    height: 450px;
    border: 0;
    border-radius: 8px;
}

section {
    margin-bottom: 50px;
    padding: 10px;
}

strong {
    color: #ff9900;
    font-weight: bold;
}

.news-card {
    background-color: #f8d7da;
    border-left: 6px solid #dc3545;
    color: #721c24;
    padding: 16px;
    margin: 20px 0;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .news-card h2 {
    margin: 0;
    font-size: 20px;
    font-weight: bold;
  }
  
  .news-card p {
    margin: 8px 0 0;
    font-size: 16px;
  }
  