header {
  color: #eee3e3;
  padding: 40px 20px;
  text-align: center;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Set to 1 so that it appears above the backgrounds */
}

.tanjay {
  font-size: 4rem;
  color: #ffffff;
  font-weight: bold;
  font-family: 'Cinzel', serif;
  margin: 0;
  z-index: 1;
  position: relative;
}

.restaurant-type {
  margin-top: 0px;
  font-size: 1.5rem;
  color: #c06418;
  font-family: 'Dancing Script', cursive;
  z-index: 1;
  position: relative;
}
/* General Image Styling */
.headerLogo {
  max-width: 41%; /* Ensure image doesn't overflow its container */
  height: auto; /* Maintain aspect ratio */
}

nav {
  position: relative;
  z-index: 2; /* Ensure the nav has a higher z-index */
}

nav ul {
  list-style: none;
  padding: 0;
}

nav ul li {
  display: inline-block;
  margin: 0 15px;
}

nav ul li a {
  color: #f9f9f9;
  font-weight:bold;
  text-decoration: none;
  border-bottom: 2px solid; /* Add an initial transparent border */
  padding-bottom: 5px; /* Add space between text and border */
  transition: border-bottom-color 0.3s ease; /* Smooth transition effect */
  z-index: 2;
}

nav ul li a:hover {
  border-bottom-color: #f9f9f9; /* On hover, change border color to match text */
  border-bottom: 4px solid; 
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&family=Dancing+Script:wght@700&display=swap');
