/* src/App.css */
@import url('https://fonts.googleapis.com/css2?family=Cinzel:wght@700&display=swap');

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

nav ul {
  list-style-type: none;
  padding: 0;
}

nav ul li {
  display: inline;
  margin: 0 15px;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

section {
  padding: 3px;
  margin: 10px auto;
  max-width: 600px;
}

h2 {
  color: #a5a5a5;
}

button {
  background-color: #c9c9c9;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 10px 15px;
  cursor: pointer;
  margin-top: 10px;
}

button:hover {
  background-color: #dfdfdf;
}

footer {
  text-align: center;
  padding: 10px;
  background-color: rgba(0,0,0,0.3);
  color: white;
}

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  background-image: url('/public/Images/background.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .wrapper {
    background-image: none;
    background-color: #333; /* Dark gray background for mobile */
  }
}