/* Footer.css */
footer {
    position: relative; /* Set to relative to allow normal flow when scrolling */
    width: 98.8%; /* Full width */
    text-align: center; /* Center the text */
    bottom: 0; /* Align to the bottom */
    margin-top: auto; /* Push the footer down when content is present */
  }

  .wrapper {
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    min-height: 100vh; /* Ensure the wrapper takes at least the full height of the viewport */
  }
  
  .main-content {
    flex: 1; /* Allow the main content to grow and take available space */
  }
  

  
.social-buttons {
  margin-top: 1rem;
}

.social-button {
  background: none;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255);
  margin: 0 15px;
  transition: transform 0.3s;
}

.social-button:hover {
  transform: scale(1.1);
  background: none;
  border: none;
}

@media (max-width: 768px) {
  .social-button {
    margin: 0 3px;
  }
}

@media (max-width: 480px) {
  .social-button {
    margin: 0 1px;
  }
}
